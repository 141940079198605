import React from "react";

// import { useTranslation } from "react-i18next";
import { readState } from "@/__main__/app-state.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
import playerImage from "@/feature-reskin-probuilds-net/utils/player-image.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Pro() {
  // const { t } = useTranslation();
  const {
    parameters: [proId],
  } = useRoute();
  const {
    lol: {
      proBuildPros = [],
      // proBuildTeams = [],
      proBuildProMatchlist = {},
    },
  } = useSnapshot(readState);

  const playerInfo = proBuildPros.find((pro) => pro.id === proId);
  // const teamInfo = proBuildTeams.find((team) => team.id === playerInfo?.teamId);
  const playerMatchlist = proBuildProMatchlist[proId];

  return (
    <Layout
      title={`${playerInfo?.name} (${playerInfo?.realName})`}
      image={playerImage(playerInfo?.portraitImageUrl)}
      alt={playerInfo?.name}
    >
      {/* {teamInfo && <span>{teamInfo.name}</span>} */}
      <Matchlist hideRoleSelect currRole={null} matches={playerMatchlist} />
    </Layout>
  );
}

export default Pro;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
